import React, { useEffect } from 'react'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    Create,
    SimpleForm,
    TextInput,
    EditButton,
    Show,
    Edit,
    SimpleShowLayout,
    SelectField,
    SelectArrayInput,
    SelectInput,
    BooleanInput,
    BooleanField,
    Filter,
    SearchInput,
} from 'react-admin'
import PersonIcon from '@material-ui/icons/Person'
import { required, minLength, email } from 'react-admin'
import api from '../../Services/Api'
export const UserIcon = PersonIcon

const validateEmail = [required(), email()]
const validadePassword = [required(), minLength(6)];

const UserFilter = props => (
    <Filter {...props}>
        <SearchInput source="filter" alwaysOn />
         <TextInput label="Razão Social" source="corporateName" />
        <SelectInput
            label="Permissões"
            alwaysOn
            source="role"
            choices={[
                // { id: 'admin', name: 'Administrador Hotéis' },
                // { id: 'user', name: 'Emissor Hotéis' },
                { id: 'Customer', name: 'Cliente final' },
                { id: 'assistanceuser', name: 'Emissor Seguros' },
                { id: 'assistanceadmin', name: 'Administrador Seguros', },
                { id: 'sysadmin', name: 'Administrador de Sistema (todas permissões)' },
            ]}
        />
    </Filter>
)

export const UserList = (props) => {
    return (<>
        <List
            {...props}
            exporter={false}
            title="Lista de Usuários"
            bulkActionButtons={false}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<UserFilter />}
        >
            <Datagrid>
                <TextFieldRa source="id" label="Código" />
                <TextFieldRa source="firstName" label="Primeiro Nome" />
                <TextFieldRa source="lastName" label="Último Nome" />
                <TextFieldRa source="email" label="E-Mail" />
                <TextFieldRa source="agentCode" label="Código Gate" />
                <TextFieldRa source="roleText" label="Perfil" />
                <TextFieldRa source="corporateName" label="Razão Social" />
                <BooleanField source="active" label="Ativo" />
                <EditButton label="" />
            </Datagrid>
        </List>
    </>
    )
}

export const UserCreate = (props) => {
    const [enterpriseList, setEnterpriseList] = React.useState([])

    useEffect(() => {
        (async () => {
            try {
                const results = await api.get(`admin/enterprises`)
                const empresasFormatted = results.data.map(result => ({
                    id: result.id,
                    name: result.corporateName
                }))
                setEnterpriseList(empresasFormatted)
            } catch (error) {
                console.log('Erro ao consultar empresas', error)
            }
        })()
    }, [])
    return (<>
        <Create {...props}>
            <SimpleForm>
                <TextInput label="Primeiro Nome" source="firstName" />
                <TextInput label="Último nome" source="lastName" />
                <TextInput
                    label="E-Mail"
                    source="email"
                    validate={validateEmail}
                />
                <TextInput
                    label="Senha"
                    source="password"
                    validate={validadePassword}
                />
                <SelectInput
                    label="Empresa"
                    source="enterpriseId"
                    choices={enterpriseList}
                />
                <SelectArrayInput
                    label="Permissões"
                    source="role"
                    choices={[
                        // { id: 'admin', name: 'Administrador Hotéis' },
                        // { id: 'user', name: 'Emissor Hotéis' },
                        { id: 'Customer', name: 'Cliente final' },
                        { id: 'assistanceuser', name: 'Emissor Seguros' },
                        { id: 'assistanceadmin', name: 'Administrador Seguros', },
                        { id: 'sysadmin', name: 'Administrador de Sistema (todas permissões)' },
                    ]}
                />
                <div style={{ width: "100%" }}>
                    <p><strong>Cliente final:</strong> compra direta por cliente final, sem acesso</p>
                    <p><strong>Emissor Seguros:</strong> acesso apenas aos seus próprios pedidos</p>
                    <p><strong>Administrador de Seguros:</strong> acesso a todos pedidos, sem acesso a outras seções</p>
                    <p><strong>Administrador de Sistema:</strong> acesso a todos pedidos, configurações e seções</p>
                </div>
                <TextInput label="Código Gate" source="agentCode" />
                <BooleanInput label="Ativo" source="active" />
            </SimpleForm>
        </Create>
    </>)
}

export const UserEdit = (props) => {
    const [enterpriseList, setEnterpriseList] = React.useState([])

    useEffect(() => {
        (async () => {
            try {
                const results = await api.get(`admin/enterprises`)
                const empresasFormatted = results.data.map(result => ({
                    id: result.id,
                    name: result.corporateName
                }))
                setEnterpriseList(empresasFormatted)
            } catch (error) {
                console.log('Erro ao consultar empresas', error)
            }
        })()
    }, [])


    const TextInputCustom = (props) => (
        <TextInput {...props} style={{ width: 400 }} />
    )
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInputCustom label="Primeiro Nome" source="firstName" />
                <TextInputCustom label="Último nome" source="lastName" />
                <TextInputCustom disabled label="E-Mail" source="email" />
                <TextInputCustom label="Código Gate" source="agentCode" />
                <SelectInput
                    label="Empresa"
                    source="enterpriseId"
                    choices={enterpriseList}
                />
                {/* <SelectInput label="Perfil" source="role" choices={[
                    { id: 'admin', name: 'Administrador' },
                    { id: 'user', name: 'Usuário' }
                ]} /> */}
                <SelectArrayInput
                    label="Permissões"
                    source="role"
                    choices={[
                        // { id: 'admin', name: 'Administrador Hotéis' },
                        // { id: 'user', name: 'Emissor Hotéis' },
                        { id: 'Customer', name: 'Cliente final' },
                        { id: 'assistanceuser', name: 'Emissor Seguros' },
                        { id: 'assistanceadmin', name: 'Administrador Seguros', },
                        { id: 'sysadmin', name: 'Administrador de Sistema (todas permissões)' },
                    ]}
                />
                <div style={{ width: "100%" }}>
                    <p><strong>Cliente final:</strong> compra direta por cliente final, sem acesso</p>
                    <p><strong>Emissor Seguros:</strong> acesso apenas aos seus próprios pedidos</p>
                    <p><strong>Administrador de Seguros:</strong> acesso a todos pedidos, sem acesso a outras seções</p>
                    <p><strong>Administrador de Sistema:</strong> acesso a todos pedidos, configurações e seções</p>
                </div>
                <BooleanInput label="Ativo" source="active" />
            </SimpleForm>
        </Edit>
    )
}

export const UserShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextFieldRa label="E-Mail" source="email" />
                <TextFieldRa label="Código Gate" source="agentCode" />
                <TextFieldRa label="Data Criação" source="createdAt" />
                <SelectField
                    label="Perfil"
                    source="role"
                    choices={[
                        // { id: 'admin', name: 'Administrador' },
                        // { id: 'user', name: 'Usuário' },
                        { id: 'Customer', name: 'Cliente final' },
                        { id: 'assistanceuser', name: 'Emissor Seguros' },
                        { id: 'assistanceadmin', name: 'Administrador Seguros', },
                        { id: 'sysadmin', name: 'Administrador de Sistema (todas permissões)' },
                    ]}
                />
            </SimpleShowLayout>
        </Show>
    )
}
