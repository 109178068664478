import React from 'react'
import {
    List,
    Datagrid,
    TextField as TextFieldRa,
    Create,
    SimpleForm,
    TextInput,
    EditButton,
    Show,
    Edit,
    SimpleShowLayout,
    Filter,
    SearchInput,
    ShowButton,
    SelectInput,
} from 'react-admin'
import BusinessIcon from '@mui/icons-material/Business';

export const EnterpriseIcon = BusinessIcon


const EnterpriseFilter = props => (
    <Filter {...props}>
        <SearchInput source="q" alwaysOn />
        <TextInput label="Cnpj" source="cnpj" />
        <TextInput label="Email" source="email" />
        <TextInput label="Id" source="id" />
    </Filter>
)

export const EnterpriseList = (props) => {
    return (<>
        <List
            {...props}
            exporter={false}
            title="Lista de Empresas"
            bulkActionButtons={false}
            sort={{ field: 'id', order: 'DESC' }}
            filters={<EnterpriseFilter />}
        >
            <Datagrid>
                <TextFieldRa source="id" label="Id" />
                <TextFieldRa source="corporateName" label="Razão Social" />
                <TextFieldRa source="cnpj" label="Cnpj" />
                <TextFieldRa source="email" label="E-Mail" />
                <TextFieldRa source="state" label="Estado" />
                <TextFieldRa source="city" label="Cidade" />
                <TextFieldRa source="status" label="Status" />
                <EditButton label="" />
                <ShowButton label="" />
            </Datagrid>
        </List>
    </>
    )
}

export const EnterpriseCreate = (props) => {
    return (<>
        <Create {...props}>
            <SimpleForm>
                <TextInput label="Razão Social" source="corporateName" required />
                <TextInput label="CNPJ" source="cnpj" required />
                <TextInput label="E-mail" source="email" required />
                <TextInput label="Telefone" source="phone" required />
                <TextInput label="CEP" source="postalCode" required />
                <TextInput label="Endereço" source="address" required />
                <TextInput label="Número" source="addressNumber" required />
                <TextInput label="Complemento" source="addressComplement" />
                <TextInput label="Bairro" source="neighborhood" required />
                <TextInput label="Cidade" source="city" required />
                <TextInput label="Estado" source="state" required />
                <TextInput label="Observação" source="note" />
                <TextInput label="Código Gate" source="codGate" />
            </SimpleForm>
        </Create>
    </>)
}

export const EnterpriseEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm redirect="list">
                <TextInput label="Razão Social" source="corporateName" required />
                <TextInput label="CNPJ" source="cnpj" required />
                <TextInput label="E-mail" source="email" required />
                <TextInput label="Telefone" source="phone" required />
                <TextInput label="CEP" source="postalCode" required />
                <TextInput label="Endereço" source="address" required />
                <TextInput label="Número" source="addressNumber" required />
                <TextInput label="Complemento" source="addressComplement" />
                <TextInput label="Bairro" source="neighborhood" required />
                <TextInput label="Cidade" source="city" required />
                <TextInput label="Estado" source="state" required />
                <TextInput label="Observação" source="note" />
                <TextInput label="Código Gate" source="codGate" />
                <SelectInput
                    label="Status"
                    source="status"
                    choices={[{ id: 0, name: 'Desativado' }, { id: 1, name: 'Ativado' }]}
                />
            </SimpleForm>
        </Edit>
    )
}

export const EnterpriseShow = (props) => {
    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextFieldRa label="Razão Social" source="corporateName" />
                <TextFieldRa label="CNPJ" source="cnpj" />
                <TextFieldRa label="E-mail" source="email" />
                <TextFieldRa label="Telefone" source="phone" />
                <TextFieldRa label="CEP" source="postalCode" />
                <TextFieldRa label="Endereço" source="address" />
                <TextFieldRa label="Número" source="addressNumber" />
                <TextFieldRa label="Complemento" source="addressComplement" />
                <TextFieldRa label="Bairro" source="neighborhood" />
                <TextFieldRa label="Cidade" source="city" />
                <TextFieldRa label="Estado" source="state" />
                <TextFieldRa label="Observação" source="note" />
                <TextFieldRa label="Código Gate" source="codGate" />
                <TextFieldRa label="Status" source="status" />
            </SimpleShowLayout>
        </Show>
    )
}
